import { useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import AddTicketForm from "../../components/AddTicketForm";
import { useState } from "react";
import { useMaintenanceSettings } from "./maintenance";
import { Spinner } from "@/components/Spinner";

export default function AddMaintenanceTicketPage() {
  const params = useParams<{ leaseId: string; propertyId: string }>();
  const [isFieldDirty, setIsFieldDirty] = useState(false);
  const [isConfirmLeaveOpen, setIsConfirmLeaveOpen] = useState(false);
  const { data: maintenanceSettings } = useMaintenanceSettings(
    params.propertyId!
  );
  const defaultPermissionToEnter = maintenanceSettings?.hasPermissionToEnter;

  const showWarning = () => {
    if (isFieldDirty) {
      setIsConfirmLeaveOpen(true);
    }
  };

  if (maintenanceSettings) {
    return (
      <Layout
        title="Add Issue"
        back={{
          label: "Maintenance Tickets",
          to: `/lease/${params.leaseId}/maintenance/${params.propertyId}`,
          showWarning: isFieldDirty,
        }}
        isConfirmLeaveOpen={isConfirmLeaveOpen}
        setIsConfirmLeaveOpen={setIsConfirmLeaveOpen}
        showWarning={showWarning}
      >
        <div className="max-w-lg mx-auto">
          <AddTicketForm
            setIsFieldDirty={(value: boolean) => setIsFieldDirty(value)}
            defaultValues={{ hasPermissionToEnter: defaultPermissionToEnter }}
          />
        </div>
      </Layout>
    );
  }

  return (
    <div className="flex items-center justify-center flex-1 my-56">
      <Spinner color="livly" size="xl" />
    </div>
  );
}

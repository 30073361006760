import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddTicketForm from "../../components/AddTicketForm";
import Drawer from "../../components/Drawer";
import { useMaintenanceSettings } from "./maintenance";
import { Spinner } from "@/components/Spinner";

export default function AddMaintenanceTicketMobilePage() {
  const navigate = useNavigate();
  const params = useParams<{ propertyId: string }>();
  const [open, setOpen] = useState(false);
  const { data: maintenanceSettings } = useMaintenanceSettings(
    params.propertyId!
  );
  const defaultPermissionToEnter = maintenanceSettings?.hasPermissionToEnter;

  useEffect(() => {
    setOpen(true);
  }, []);

  const onClose = () => {
    setOpen(false);
    setTimeout(() => navigate(-1), 500);
  };

  if (maintenanceSettings) {
    return (
      <Drawer open={open} onClose={onClose} title="Add Ticket">
        <AddTicketForm
          defaultValues={{ hasPermissionToEnter: defaultPermissionToEnter }}
        />
      </Drawer>
    );
  }

  return (
    <div className="flex items-center justify-center flex-1 my-56">
      <Spinner color="livly" size="xl" />
    </div>
  );
}
